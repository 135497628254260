export const handleOnMessage = (eventSource, setReceivedMessages) => {
    if (eventSource) {
        eventSource.onmessage = function (event) {
            const message = JSON.parse(event.data);
            const newMessage = { 'topic': message.topic, 'payload': message.payload }

            console.log('New message received:', newMessage);

            setReceivedMessages(prevMessages => {
                // Add the new message to the beginning of the array
                const updatedMessages = [newMessage, ...prevMessages];

                // Limit the array to the most recent 10 messages
                if (updatedMessages.length > 10) {
                    updatedMessages.splice(10); // Remove the oldest message
                }

                return updatedMessages;
            });
        };
    } else {
        console.error("EventSource is not ready");
    }
};


// export const handleOnMessage = (eventSource, setReceivedMessages, userTopic) => {
//     if (eventSource) {
//         let filteredMessages = []
//         eventSource.onmessage = function (event) {
//             const messages = JSON.parse(event.data);
//             console.log(messages)

//             // NOT WORKING RIGHT
//             setReceivedMessages(prevMessages => {
//                 // Create a set of existing message IDs to avoid duplicates
//                 const existingMessageIds = new Set(prevMessages.map(msg => msg.payload.event_id));

//                 // Filter out duplicates
//                 filteredMessages = messages.filter(message => 
//                     matchTopic(message.topic, userTopic) &&
//                     !existingMessageIds.has(message.payload.event_id)
//                 );

//                 // Add the new message to the beginning of the array
//                 const updatedMessages = [...filteredMessages, ...prevMessages];

//                 return updatedMessages;
//             });
//         };
//     } else {
//         console.error("EventSource is not ready");
//     }
// };

// const matchTopic = (topic, userTopic) => {
//     // Convert userTopic to a regex pattern
//     const pattern = userTopic
//         .split('/')
//         .map(part => part === '+' ? '[^/]*' : part)
//         .join('/');
//     const regex = new RegExp(`^${pattern}$`);
//     return regex.test(topic);
// };