import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Box, Typography, FormControl, InputLabel, MenuItem, Select, Button } from "@mui/material";
import RefreshIcon from "@mui/icons-material/Refresh";
import { isTokenExpired } from '../functions/authFunctions';
import { handleOnMessage } from "../functions/connectionFunctions"
import { getStructuredDict } from "../api/connection";
import { baseURL } from "../config"

// FOR CONNECTION BUTTON and MAP DISPLAY
import { useSetRecoilState, useRecoilState } from "recoil";
import { subscribeTopicAtom, receivedMessagesAtom, eventSourceAtom } from "../recoil/atom";

const ConnectionPage = () => {
    const [structuredDict, setStructuredDict] = useState({});
    const [siteList, setSiteList] = useState([]);
    const [clusterList, setClusterList] = useState([]);
    const [rfList, setRfList] = useState([]);

    const user = localStorage.getItem("user");
    const [client, setClient] = useState("");
    const [project, setProject] = useState("");
    const [site, setSite] = useState("")
    const [cluster, setCluster] = useState("");
    const [rf, setRf] = useState("");

    const [subscribeTopic, setSubscribeTopic] = useRecoilState(subscribeTopicAtom);
    const [receivedMessages, setReceivedMessages] = useRecoilState(receivedMessagesAtom);
    const [eventSource, setEventSource] = useRecoilState(eventSourceAtom);

    const navigate = useNavigate();


    useEffect(() => {
        const token = localStorage.getItem("token");
        if (isTokenExpired(token)) { 
            navigate("/login");
        } else {
            setClient(JSON.parse(user).client_name);
        }
    }, []);

    useEffect(() => {
        if (client) {
            fecthStructuredDict();
        };
    }, [client]);

    useEffect(() => {
        setSite('');
        setSiteList([]);
        setCluster('');
        setClusterList([])
        setProject('');
    }, [structuredDict]);

    useEffect(() => {
        setCluster('');
        setClusterList([]);
        setSite('');

        if (Object.keys(structuredDict).length > 0 && project) {
            setSiteList(Object.keys(structuredDict[project]))
        };
        
    }, [project]);

    useEffect(() => {
        setRf('');
        setRfList([]);
        setCluster('');

        if (Object.keys(structuredDict).length > 0 && site) {
            setClusterList(Object.keys(structuredDict[project][site]))
        };
        
    }, [site]);

    useEffect(() => {
        setRf('');

        if (Object.keys(structuredDict).length > 0 && cluster) {
            setRfList(structuredDict[project][site][cluster])
        };
        
    }, [cluster]);


    // GET STRUCTURED DICT FROM DB
    const fecthStructuredDict = async () => {
        try {
            const response = await getStructuredDict(client);
            // console.log(response)
            setStructuredDict(response.data)
        } catch (error) {
            console.log(error)
        };
    };

    // REFRESH FORM
    const handleRefresh = () => {
        setProject('');
        setSite('');
        setSiteList([]);
        setCluster('');
        setClusterList([]);
        setSubscribeTopic('');
    };

    // CONNECT
    const handleConnectButton = async () => {
        setReceivedMessages([]); // to clear and data that came in before
        let userTopic = ''
        if (client && !project) {   // only client data
            userTopic = `lpr/${client}/+/+/+/+`
        } else if (project && !site) { // client and project data
            userTopic = `lpr/${client}/${project}/+/+/+`
        } else if (site && !cluster) { // client, project and site data
            userTopic = `lpr/${client}/${project}/${site}/+/+`
        } else if (cluster && !rf) { // client, project, site, and cluster data
            userTopic = `lpr/${client}/${project}/${site}/${cluster}/+`
        } else {    // all the data
            userTopic = `lpr/${client}/${project}/${site}/${cluster}/${rf}`
        }

        console.log('userTopic:', userTopic)
        setSubscribeTopic(userTopic);
      
        // Send the topic and user ID to the backend for filtering and receive messages
        const newEventSource = new EventSource(`${baseURL}/filter_messages?user_topic=${encodeURIComponent(userTopic)}`);
        setEventSource(newEventSource)

        handleOnMessage(newEventSource, setReceivedMessages);

        setReceivedMessages([]); // to clear and data that came in before
        navigate('/');
    };



    return (
        <Box
            sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                height: '90vh'
            }}
        >
            <Box
                sx={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                    marginBottom: "4vh",
                    border: "1px solid grey",
                    borderRadius: "8px"
                }}
            >
                <Typography
                    sx={{
                        margin: "5vh 5vh 3vh 5vh",
                        width: "300px",
                        fontSize: "20px"

                    }}
                >
                    {client}
                </Typography>
                <FormControl
                    fullWidth
                    sx={{
                        margin: "0vh 5vh 1vh 5vh",
                        width: "300px",
                    }}
                >
                    <InputLabel id="labelProject">Project</InputLabel>
                    <Select
                        disabled={!client}
                        labelId="labelProject"
                        value={project}
                        label="Project"
                        onChange={(e) => setProject(e.target.value)}
                    >
                        {Object.keys(structuredDict).map((projectPulled, index) => (
                            <MenuItem key={index} value={projectPulled}>
                                {projectPulled}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
                <FormControl
                    fullWidth
                    sx={{
                        margin: "0vh 5vh 1vh 5vh",
                        width: "300px",
                    }}
                >
                    <InputLabel id="labelSite">Site</InputLabel>
                    <Select
                        disabled={!project}
                        labelId="labelSite"
                        value={site}
                        label="Site"
                        onChange={(e) => setSite(e.target.value)}
                    >
                        {siteList.map((sitePulled, index) => (
                            <MenuItem key={index} value={sitePulled}>
                                {sitePulled}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
                <FormControl
                    fullWidth
                    sx={{
                        margin: "0vh 5vh 1vh 5vh",
                        width: "300px",
                    }}
                >
                    <InputLabel id="labelCluster">Cluster</InputLabel>
                    <Select
                        disabled={!site}
                        labelId="labelCluster"
                        value={cluster}
                        label="Cluster"
                        onChange={(e) => setCluster(e.target.value)}
                    >

                        {clusterList.map((clusterPulled, index) => (
                            <MenuItem key={index} value={clusterPulled}>
                                {clusterPulled}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
                <FormControl
                    fullWidth
                    sx={{
                        margin: project ? "0vh 5vh 2vh 5vh" : "0vh 5vh 5vh 5vh",
                        width: "300px",
                    }}
                >
                    <InputLabel id="labelRf">Sensor</InputLabel>
                    <Select
                        disabled={!cluster}
                        labelId="labelRf"
                        value={rf}
                        label="Rf"
                        onChange={(e) => setRf(e.target.value)}
                    >

                        {rfList.map((rfPulled, index) => (
                            <MenuItem key={index} value={rfPulled}>
                                {rfPulled}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
                {project && (
                    <RefreshIcon fontSize="small" sx={{
                        margin: "0vh 5vh 4vh 5vh"
                    }}
                        onClick={handleRefresh}
                    />
                )}
            </Box>
            <Button
                disabled={!client}
                variant="contained"
                sx={{
                    width: "370px",
                    borderRadius: "30px",
                }}
                color="success"
                onClick={handleConnectButton}
            >
                Connect
            </Button>
        </Box >
    )
}

export default ConnectionPage;