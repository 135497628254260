import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Box, TextField, Button, Typography, FormLabel } from '@mui/material';
import { login, loginGoogle, subscribe } from "../api/auth";
import { isValidEmail, isTokenExpired } from '../functions/authFunctions';

import { GoogleLogin } from '@react-oauth/google';
import { jwtDecode } from 'jwt-decode';


const LoginPage = () => {
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [totp, setTotp] = useState("");
    const [errorMsg, setErrorMsg] = useState("");
    const [googleErrorMsg, setGoogleErrorMsg] = useState("");

    const navigate = useNavigate();

    useEffect(() => {
        const checkAndSubscribe = async () => {
            const token = localStorage.getItem("token");
            const user = localStorage.getItem("user");
            if (!isTokenExpired(token) && user) {
                navigate("/connection");
                await subscribeToTopic();
            }
        };
    
        checkAndSubscribe();
    }, []);


    const loginUser = async () => {
        if (isValidEmail(email) && password !== "" && totp) {
            try {
                const response = await login(email, password, totp);
                localStorage.setItem("user", JSON.stringify(response.data));
                localStorage.setItem("token", response.data.token);
                navigate('/connection')
                await subscribeToTopic();
            } catch (error) {
                console.log(error)
                setErrorMsg(error.response.data.error)
            }
        } else if (!isValidEmail(email) && email !== "" && password !== "" && totp !== "") {
            setErrorMsg("Please enter a valid email");
        } else {
            setErrorMsg("All fields must be filled")
        }
    };

    const loginGoogleUser = async (email) => {
        try {
            const response = await loginGoogle(email);
            localStorage.setItem("user", JSON.stringify(response.data));
            localStorage.setItem("token", response.data.token);

            navigate('/connection')
            await subscribeToTopic();
        } catch (error) {
            console.log(error)
            setGoogleErrorMsg(error.response.data.error)
        }
    };

    const subscribeToTopic = async () => {
        const universalTopic = 'lpr/+/+/+/+/+';

        try {
            await subscribe(universalTopic);
        } catch (error) {
            console.error('Error subscribing to topic:', error);
        };
    };

    return (
        <Box
            sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                height: '100vh'
            }}
        >
            <FormLabel
                sx={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                    marginBottom: "2vh",
                    border: "1px solid grey",
                    borderRadius: "8px"
                }}
            >
                <TextField
                    required
                    sx={{
                        margin: "5vh 5vh 1vh 5vh",
                        width: "300px",
                    }}
                    id="email"
                    label="Email"
                    variant="outlined"
                    value={email}
                    onChange={(e) => { setEmail(e.target.value) }}
                />
                <TextField
                    required
                    type="password"
                    sx={{
                        margin: "1vh 5vh 1vh 5vh",
                        width: "300px",
                    }}
                    id="password"
                    label="Password"
                    variant="outlined"
                    value={password}
                    onChange={(e) => { setPassword(e.target.value) }}
                />
                <TextField
                    required
                    sx={{
                        margin: "1vh 5vh 5vh 5vh",
                        width: "300px",
                    }}
                    id="totp"
                    label="TOTP"
                    variant="outlined"
                    value={totp}
                    onChange={(e) => { setTotp(e.target.value) }}
                />
            </FormLabel>
            <Typography
                sx={{
                    color: "red",
                    marginBottom: "2vh"
                }}
            >
                {errorMsg}
            </Typography>

            <Button
                variant="contained"
                sx={{
                    width: "370px",
                    backgroundColor: "#0A1B3A",
                    borderRadius: "30px",
                    '&:hover': {
                        backgroundColor: "#37465E",
                    }
                }}
                onClick={() => { loginUser() }}
            >
                Log in
            </Button>
            <Box
                sx={{
                    display: "flex",
                    margin: "2vh"
                }}            >
                <Typography>
                    or
                </Typography>
            </Box>
            <Box
                sx={{
                    display: "flex",
                    justifyContent: "center",
                    width: "370px",
                    marginBottom: "3vh"
                }}
            >
                <GoogleLogin
                    onSuccess={(credentialResponse) => {
                        const credentialResponseDecode = jwtDecode(credentialResponse.credential)
                        // console.log(credentialResponseDecode)
                        const email = credentialResponseDecode.email
                        loginGoogleUser(email);
                    }}
                    onError={() => {
                        console.log('LoginFailed')
                    }}
                />
            </Box>
            <Typography
                sx={{ color: "red" }}
            >
                {googleErrorMsg}
            </Typography>

            <Box
                sx={{
                    display: "flex",
                    alignItems: "center",
                }}
            >
                <Typography>Don't have an account?</Typography>
                <Button
                    sx={{ fontWeight: "bold" }}
                    component={Link}
                    to={`/register`}
                >
                    Register
                </Button>
            </Box>
        </Box >
    )
}

export default LoginPage;